@import "~@/assets/scss/global.scss";














































.layout-middle {
  position: relative;
  height: calc(100vh - #{$base-header-height} - #{$base-footer-height});
}
