@import "~@/assets/scss/global.scss";




























.layout-wrapper {
  height: 100%;
  width: 100%;
  position: relative;

  .mask {
    background: #ffffff;
    bottom: 0;
    height: 100vh;
    left: 0;
    opacity: 0.5;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: $base-z-index - 1;
  }
}
