@import "~@/assets/scss/global.scss";






























































































































































































.checkbox-inner {
  margin-right: 0;
}
