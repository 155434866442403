@import "~@/assets/scss/global.scss";







































#layout-main {
  height: 100%;
  background: rgba(233, 237, 241, 1);

  #main-wrapper {
    padding: 12px;
    height: calc(100% - 65px);
    overflow: auto;

    #main-container {
      background: white;
      border-radius: 4px;
      height: 100%;
    }
  }
}
