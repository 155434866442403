@import "~@/assets/scss/global.scss";









































































.button-group {
  .button {
    display: inline-block;
    margin: 0 5px 5px 0;
    padding: 3px 10px;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, 0);
    cursor: pointer;
    .el-icon-arrow-down {
      margin-left: 4px;
    }

    .button-icon {
      font-size: 16px;
      vertical-align: text-bottom;
    }

    &:hover {
      border: 1px solid #ecf5ff;
      border-radius: 2px;
      background: #ecf5ff;
    }
  }
  .divider {
    margin: 0 10px 0 5px;
  }
}
