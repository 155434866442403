@import "~@/assets/scss/global.scss";


































































































































































































































.tabs {
  height: 38px;
  width: 100%;
  background: white;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding-right: $base-padding;
  padding-left: $base-padding;
  user-select: none;

  ::v-deep {
    .fold-unfold {
      margin-right: $base-padding;
    }
  }

  .tabs-content {
    height: $base-tag-item-height;
    width: calc(100% - 100px);

    ::v-deep {
      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        height: $base-tag-item-height;
        line-height: $base-tag-item-height;
      }

      .el-tabs__header {
        border-bottom: 0;

        .el-tabs__nav {
          border: 0;
        }

        .el-tabs__item {
          border: 1px solid $base-border-color;
          border-radius: $base-border-radius;
          box-sizing: border-box;
          height: $base-tag-item-height;
          line-height: $base-tag-item-height;
          margin-right: 5px;
          transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

          &.is-active {
            border: 1px solid $base-color-blue;
          }
        }
      }
    }
  }

  .more {
    align-content: center;
    align-items: center;
    cursor: pointer;
    display: flex;
  }
}
