@import "~@/assets/scss/global.scss";














































































































































































































































































































































.grid-table {
  .el-button {
    margin: 0 6px;
  }

  ::v-deep {
    .el-table__header {
      .el-tag {
        background-color: #3a8ee6;
        border-color: #3a8ee6;
        color: white;
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        font-size: 10px;
        cursor: pointer;
        margin-left: 2px;
      }
    }

    .el-table__header tr,
    .el-table__header th {
      padding: 0;
      height: 38px !important;
      line-height: 38px !important;
    }

    .el-image__inner {
      height: 50px;
      width: 50px;
    }

    .el-pagination__sizes {
      .el-input__inner {
        height: 22px;
      }

      .el-input__icon {
        line-height: 22px;
      }
    }

    .is-disabled {
      input {
        color: #878c96 !important;
      }
    }
  }
}
