@import "~@/assets/scss/global.scss";

































.layout-footer {
  width: 100vw;
  background: white;
  height: $base-footer-height;
  line-height: $base-footer-height;
  text-align: center;
  font-size: 13px;
  font-weight: 400;

  a {
    color: #333;

    img {
      vertical-align: middle;
      height: 20px;
      width: 20px;
    }
  }
}
