@import "~@/assets/scss/global.scss";
























































































.page-search {
  border-top: 1px solid #dcdfe6;
  padding-top: 7px;

  .el-form {
    display: inline-block;
    vertical-align: top;
    ::v-deep {
      .el-form-item {
        margin-bottom: 7px;
      }
    }
  }

  .collapse-block {
    display: none;
  }
}
