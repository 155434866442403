@import "~@/assets/scss/global.scss";












































.layout-navbar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: black;
  width: 48px;
  overflow: auto;
  font-size: 12px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .navbar {
    min-height: 620px;
    text-align: center;
    width: 100%;
    float: left;
    color: white;
    .menu {
      line-height: 20px;
      height: 60px;
      cursor: pointer;
      &:hover {
        background: white;
        color: rgba(0, 117, 227, 1);
      }

      &.active {
        background: white;
        color: rgba(0, 117, 227, 1);
      }

      i {
        font-size: 20px;
        margin-top: 10px;
      }
    }
  }
}
