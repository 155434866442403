@import "~@/assets/scss/global.scss";






































.layout-sub-navbar {
  position: absolute;
  top: 0;
  left: 48px;
  height: 100%;
  width: 154px;
  font-size: 12px;
  background: white;
  overflow: auto;

  .sub-menu {
    min-height: 650px;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::v-deep {

    .el-submenu__title,
    .el-menu-item {
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid rgb(243 242 242);
    }
  }
}
