@import "~@/assets/scss/global.scss";



























































.layout-header {
  width: 100vw;
  background: $base-color-blue;
  height: $base-header-height;
  line-height: $base-header-height;
  position: relative;
  color: rgba(255, 255, 255, 1);
  .header-left {
    font-size: 20px;
    font-weight: bold;
    img {
      margin-left: 20px;
      width: 172px;
      height: 40px;
      vertical-align: middle;
      object-fit: contain;
    }
    .el-divider {
      margin: 0 19px;
      height: 26px;
    }
  }
  .header-right {
    position: absolute;
    top: 0;
    right: 11px;
    font-size: 14px;
    font-weight: 400;
    height: 100%;

    > div {
      display: inline-block;
      padding: 0 20px;
      height: 100%;
      cursor: pointer;
      position: relative;
      
      &:hover {
        background: white;
        color: rgba(0, 117, 227, 1);
      }
    }

    .el-avatar {
      vertical-align: middle;
      margin-right: 12px;
      img {
        height: 16px;
        width: 16px;
        object-fit: contain;
      }
    }

    .el-divider {
      height: $base-header-height;
      width: 1px;
      background-color: rgba(0, 109, 211, 1);
      margin: 0px;
      padding: 0px;

      &.el-divider--vertical {
        vertical-align: top;
      }
    }
  }
}
