@import "~@/assets/scss/global.scss";

































.boder-box {
  margin: 20px 5px 5px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px 10px 3px 10px;
  position: relative;

  .boder-box-title {
    position: absolute;
    left: 10px;
    top: -15px;
    background: white;
    padding: 5px 8px;
  }
}
